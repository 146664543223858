<template>
  <div class="container">
    <div class="videos">
      <media :query="{maxWidth: 525}">
        <div class="mobile-vid_header">08 Сен 2020, 21:00 DreamEaters—NAVI / Game 1</div>
      </media>
      <div class="videos-player">
<!--        <lazy-youtube-video src="https://www.youtube.com/embed/dQw4w9WgXcQ"/>-->
      </div>
      <div class="videos-list">
        <div class="title">Записи трансляций</div>
        <div class="videos-wrapper">
          <!--Карточка видео-->
          <div class="video-card active">
            <div class="card-title">
              08 Сен 2020, 21:00 DreamEaters—NAVI / Game 1
            </div>
            <div class="competitors">
              <div class="single-competitor">
                <div class="status winner">В</div>
                <div class="team-profile">
                  <div class="logo">
                    <img src="~@/img/test-logo2.png" alt="#">
                  </div>
                  <div class="name">NAVI</div>
                </div>
              </div>
              <div class="single-competitor reverse">
                <div class="status">П</div>
                <div class="team-profile">
                  <div class="logo">
                    <img src="~@/img/test-logo2.png" alt="#">
                  </div>
                  <div class="name">NAVI</div>
                </div>
              </div>
            </div>
          </div>
          <div class="video-card" v-for="vCard in 10" :key="vCard">
            <div class="card-title">
              08 Сен 2020, 21:00 DreamEaters—NAVI / Game 1
            </div>
            <div class="competitors">
              <div class="single-competitor">
                <div class="status winner">В</div>
                <div class="team-profile">
                  <div class="logo">
                    <img src="~@/img/test-logo2.png" alt="#">
                  </div>
                  <div class="name">NAVI</div>
                </div>
              </div>
              <div class="single-competitor reverse">
                <div class="status">П</div>
                <div class="team-profile">
                  <div class="logo">
                    <img src="~@/img/test-logo2.png" alt="#">
                  </div>
                  <div class="name">NAVI</div>
                </div>
              </div>
            </div>
          </div>
          <!--Карточка видео-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Media from 'vue-media'


export default {
  name: "tournamentVideos",
  components: {Media},
}
</script>

<style scoped>

</style>